// @mui
import { useCallback, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
// import props from 'prop-types';
// import moment from 'moment/min/moment-with-locales';
import moment from 'moment/moment';
import 'moment-timezone';

import { m } from 'framer-motion';
import Divider from '@mui/material/Divider';
import { useScrollTrigger, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Badge, { badgeClasses } from '@mui/material/Badge';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgBlur } from 'src/theme/css';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import Logo from 'src/components/logo';
import Label from 'src/components/label';
import { MotionViewport, varFade } from 'src/components/animate';
import { RouterLink } from 'src/routes/components';
//
// import HeadLineCard from 'src/sections/blog/headline-card';

// import PostCarousel from './headline';
import HeadlineNews from '../../sections/main-home/headline';

import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
//
import { SettingsButton, HeaderShadow, LoginButton } from '../_common';

// ----------------------------------------------------------------------

export default function Header() {
  const settings = useSettingsContext();

  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const [topPosition, setTopPosition] = useState(100); // Start from 100px
  const [triggered, setTriggered] = useState(false);

  const [currentTime, setCurrentTime] = useState(moment().locale("bn").format('LLLL'));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().locale("bn").format('LLLL'));
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 110,
  });

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop >= 110) {
      setTopPosition(0);
      setTriggered(true);
    } else {
      setTopPosition(100 - (100 * (scrollTop / 110)));
      setTriggered(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  return (
      <>
      <Container
        sx={{ height: 1, display: 'flex', alignItems: 'center', textAlign: 'center',
        justifyContent:'space-between'

      }}
        maxWidth={settings.themeStretch ? false : 'lg'}
      >

      {mdUp ?
        <>
        <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
          <Box
            component="img"
            src='/logo/full logo.png'
            sx={{ height: 60, cursor: 'pointer', m: 2
            }}
            />
        </Link>

          <Typography
            sx={{
              my: { xs: 2, md: 2 },
              mx: { xs: 2, md: 2 },
              textAlign: 'center'
            }}
          >
              <Box
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  m: 1
                }}
              >


                <IconButton onClick={() => window.open('https://www.facebook.com/editorialnews24')} color="success" size="small">
                <Iconify icon="line-md:facebook" width={20} />
                </IconButton>

                <IconButton onClick={() => window.open('https://www.youtube.com/@editorialnews24')} color="success" size="small">
                <Iconify icon="line-md:youtube" width={20} />
                </IconButton>

                <IconButton onClick={() => window.open('https://x.com/editorialnews24')}  color="success" size="small">
                <Iconify icon="line-md:twitter-x-alt" width={20} />
                </IconButton>

                <IconButton onClick={() => window.open('https://www.linkedin.com/company/13681979/admin/dashboard/')} color="success" size="small">
                  <Iconify   icon="line-md:linkedin" width={20} />
                </IconButton>

                <IconButton onClick={() => window.open('https://www.instagram.com/editorialnews24/')} color="success" size="small">
                  <Iconify   icon="line-md:instagram" width={20} />
                </IconButton>

                {/* <IconButton onClick={() => window.open('https://www.editorialnews24.com/')} color="success" size="small">
                  | বাংলা |
                </IconButton> */}

              </Box>

              <Box
              >
                {currentTime}
              </Box>

            </Typography></>

            : ''}
      </Container>

      <AppBar
      sx={{
        bottom: 'auto',
        top: `${mdUp ? topPosition : 0}px`, // Apply dynamic top position if mdUp is true
        transition: 'top 0s ease-out', // Smooth transition for the top position
        ...(triggered && {
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }),
      }}
      >
        <Divider />
        <Toolbar
          disableGutters
          sx={{
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_DESKTOP,
            },

            transition: theme.transitions.create(['height'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            ...(offsetTop && {
              ...bgBlur({
                color: theme.palette.background.default,
              }),
              height: {
                md: HEADER.H_DESKTOP_OFFSET,
              },
            }),
          }}
        >
          <Container maxWidth={settings.themeStretch ? false : 'lg'} sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
            <Badge
              sx={{
                [`& .${badgeClasses.badge}`]: {
                  top: 8,
                  right: -16,
                },
              }}
            >
              { trigger && mdUp ? <Logo />:''}
              { !mdUp ? <Logo />:''}
            </Badge>

            <Box sx={{ flexGrow: 1 }} />

            {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

            <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
                <IconButton onClick={() => window.open('https://www.editorialnews24.com/')} color="success" size="small">
                  | বাংলা |
                </IconButton>

              <m.div variants={varFade().inUp}>
                <IconButton onClick={() => settings.onUpdate('themeMode', settings.themeMode === 'light' ? 'dark' : 'light')} color="inherit">
                  {settings.themeMode === 'dark' ? <Iconify icon="line-md:light-dark-loop" width={24} /> : <Iconify icon="line-md:light-dark-loop" width={24} />}
                </IconButton>
              </m.div>

              {/* <Button variant="contained" target="_blank" rel="noopener" href='#'>
              Login
            </Button> */}

              {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}
            </Stack>
          </Container>
        </Toolbar>

        {offsetTop && <HeaderShadow />}
        <Divider sx={{ borderBottomWidth: 5 }}  />
      </AppBar>
      </>
  );
}
