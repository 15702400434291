// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _socials } from 'src/_mock';
// components
import { useSettingsContext } from 'src/components/settings';

import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Chapter',
    children: [
      { name: 'Latest', href: paths.latestNews.root },
      { name: 'Crime', href: paths.crimeNews.root },
      { name: 'Bangladesh', href: paths.bangladesh.root },
      { name: 'Contact us', href: paths.contact },
    ],
  },
  {
    headline: 'Chapter',
    children: [
      { name: 'Politics', href: paths.politics.root },
      { name: 'International', href: paths.internationalNews.root },
      { name: 'Recent', href: paths.recentNews.root },
      { name: 'About', href: paths.about },
    ],
  },
  {
    headline: 'Chapter',
    children: [
      { name: 'Economy', href: paths.theEconomy.root },
      { name: 'Sports', href: paths.sportsNews.root },
      { name: 'Entertainment', href: paths.entertainment.root },
      { name: 'Expatriate Life', href: paths.expatriateLife.root }
    ],

  },
  {
    headline: 'Chapter',
    children: [
      { name: 'Job News', href: paths.jobNews.root },
      { name: 'Opinion', href: paths.opinion.root },
      { name: 'Life Style', href: paths.lifeStyle.root },
      { name: 'videos', href: paths.videos.root },
    ],
  },
  {
    headline: 'Editorialnews24',
    children: [
      { name: 'About us', href: paths.aboutus },
      { name: 'Contact us', href: paths.contactus },
      { name: 'Privacy Policy', href: paths.privacy },
      { name: 'Terms and Conditions', href: paths.terms },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const settings = useSettingsContext();

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
        maxWidth={settings.themeStretch ? false : 'lg'}
      >
        <Logo sx={{ mb: 3 }} />

        {/* <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
          <Box
            component="img"
            src='/logo/full logo.png'
            sx={{ height: 60, cursor: 'pointer', m: 2
            }}
            />
        </Link> */}
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={4}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              One of the most reliable and comprehensive sources of news and
              international news. It provides in-depth analysis,
              breaking news and multimedia content covering a wide
              range of topics including crime, politics, business,
              technology, science, health and entertainment.

            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    '&:hover': {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                  onClick={() => window.open(social.path)}
                >
                  <Iconify color={social.color} icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid xs={12} md={8}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ mt: 5, textAlign:'center' }}>
          © 2024. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return  mainFooter;
}
