import PropTypes from 'prop-types';
import React from "react";
import Slider from "react-slick";
// @mui
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import TextMaxLine from 'src/components/text-max-line';
import { Button } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// components
import Image from 'src/components/image';
import Carousel, { CarouselArrowIndex, useCarousel } from 'src/components/carousel';

// ----------------------------------------------------------------------

export default function HeadlineNews({ data }) {

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    speed: 25000,
    autoplaySpeed: 0,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const linkTo  = (id) => paths.latestNews.details(id);

  return (
    <Card sx={{borderRadius: .5}}>

      <Slider {...settings}>
      {data?.map((item) => (
        <Stack key={item?.id}>
          <CardContent sx={{ textAlign: 'left', m:0 }}  >
              <Link color="inherit" component={RouterLink} href={linkTo(item?.id)}>
                <TextMaxLine variant="body2"
                sx={{
                  width:350,
                  textAlign:'left',
                  textTransform: 'capitalize'
                }}
                line={1}
                >
                {item?.fix_category_bn}: {item?.title_bn}
                </TextMaxLine>
              </Link>
            </CardContent>
        </Stack>
      ))}
      </Slider>
    </Card>
  );
}

HeadlineNews.propTypes = {
  data: PropTypes.array,
};
